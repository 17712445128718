@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 鼠标悬停时显示滚动条 */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #83878e; /* 修改滚动条颜色 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4a5568; /* 修改悬停时滚动条颜色 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  /* background-color: #f1f5f9; 修改滚动条轨道颜色 */
  background-color: #0000;
}
